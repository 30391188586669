@import "../../index.scss";

.content-row {
    padding: 1px 12px;
    border-bottom: 1px solid #DADADA;

    div {
        padding: 4px 5px;
    }

    .delete-icon-wrapper {

        img {
            @media (min-width: 768px) {
                max-width: 80px;
            }

            @media (min-width: 1260px) {
                max-width: 100%;
            }
        }

        .content-delete-option {
            border: none;
            background-color: white;
            height: fit-content;
            --bs-btn-padding-y: 4px;
            padding: 0px 6px;

            svg {
                width: 24px;
                height: 24px;

                path {
                    fill: #A6BCC6;
                }
            }

            &:hover,
            &:focus-visible {
                path {
                    fill: $elysia-Sky-color;
                }
            }
        }
    }

}

.content-row-sort {
    width: 16px;
    height: 16px;
    position: relative;
    bottom: 1px;
    left: 10px;
    cursor: pointer;
}

.content-dragzone-div {
    height: 240px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px dashed var(--INFORMA-Light-Grey, #DADADA);
    background: var(--INFORMA-White, #FFF);
    cursor: pointer;

    .upload-left-panel {
        width: 50%;

        .drag-drop-container {
            border-right: 1px solid $informa-grey-color;
        }
    }

    .upload-right-panel {
        width: 50%;

        .browse-files {
            border: 1px solid $light-grey-color;
            border-radius: 3px;
            font-weight: 600;
            width: 165px;
            margin: 0 auto;
        }
    }

}

button {
    padding: 6px 12px;
}

.button-secondary {
    border-radius: 3px;
    border: 1px solid var(--INFORMA-Light-Grey, #DADADA);
    background: var(--INFORMA-White, #FFF);
    /* ELYSIA Dropshadow */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    color: var(--INFORMA-Indigo, #024);
    text-align: center;

}

.content-list-wrapper {
    background: var(--INFORMA-White, #FFF);
    border-top: 1px solid #DADADA;

    .content-filter-wrapper {
        position: sticky;
        top: 0;
        background: var(--INFORMA-White, #FFF);
    }

    .system-info-msg {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.collection-dropdown-wrapper {
    margin: 12px;

    .collection-dropdown-label {
        position: relative;
        top: 5px;
        padding-right: 5px;
    }

    .collection-dropdown {
        font-size: 14px;
        font-weight: 500;
        border-radius: 3px;

        &:focus {
            border-color: $elysia-Sky-color;
            box-shadow: none;
        }
    }

    .refresh-collection {
        padding: 5px 10px;
    }
}

.content-row-header {
    clear: both;
    font-weight: 400;
    padding: 0px 12px;
    border-bottom: 1px solid #DADADA;

    div {
        padding: 7px 5px;
    }
}

.search-collection-input-wrapper {
    margin: 12px;
    flex-grow: 2;

    .search-icon {
        position: absolute;
        top: 20px;
        left: 21px;
    }

    .search-collection-input {
        padding-left: 30px;
        max-width: 304px;
        border-radius: 5px;

        &:focus {
            border-color: $elysia-Sky-color;
            box-shadow: none;
        }
    }
}

.delete-modal {
    .modal-content {
        padding: 24px;
        border-radius: 5px;
        border-color: $elysia-Sky-color;

        .modal-header {
            border-bottom: 0;
            padding: 0px;

            .modal-title {
                font-size: 18px;
                font-weight: 700;
            }
        }

        .modal-body {
            padding: 0px;
            margin-top: 10px;

            span {
                margin-bottom: 10px;
            }

            .delete-confirm-msg {
                margin: 10px 0px;
                display: inline-block;
            }

            svg {
                margin-right: 20px;
                height: 30px;
                width: 30px;
            }
        }

        .modal-footer {
            padding: 10px 0px 0px;
            justify-content: space-between;

            button.modal-proceed {
                border-color: $elysia-Sky-color;
            }

            button.modal-cancel {
                border-color: $light-grey-color;
            }

            button {
                background: #FCFCFC;
                color: #002244;
                height: 40px;
                font-size: 14px;
                border-radius: 3px;
                font-weight: 600;

                &:hover,
                &:focus,
                &:focus-visible {
                    color: $elysia-Sky-color !important;
                    border-color: $elysia-Sky-color;
                }
            }

        }
    }
}

.delete-modal-backdrop {
    background-color: rgba(255, 255, 255, 0.8);
}

.mandatory-asterisk {
    color: $elysia-coral;
}

.upload-collection-list {
    font-size: 14px;
    font-weight: 500;
}

.width-control {
    width: 86.67%;

    @media (min-width: 768px) {
        width: 624px;
    }

    @media (min-width: 960px) {
        width: 834px;
    }

    @media (min-width: 1260px) {
        width: 834px;
    }

    @media (min-width: 1440px) {
        width: 834px;
    }
}

.system-info-msg.file-upload {
    img {
        width: 30px;
        height: 30px;
    }
}