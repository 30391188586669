@import "../../index.scss";

.profile-info-message {
    color: var(--INFORMA-Blue, #0A7DA4);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex-basis: 100%;
    margin-top: 15px;
    padding-bottom: 12px;
    padding-left: 15px;
    border-bottom: 1px solid $light-grey-color;
}

.profile-edit-popup {
    padding: 24px;
}

.profile-page {
    .my-profile-title {
        color: $informa-indigo;
    }

    .menu-option-box {
        border: 1px solid $elysia-Sky-color;
        border-radius: 5px;
        background-color: $primary-white-color;
        box-shadow: 0px 4px 4px 0px #0000000d;

        button.edit {

            &:hover,
            &:focus,
            &:focus-visible {
                path {
                    fill: $elysia-Sky-color;
                }
            }
        }

        textarea.edit-about {
            margin: 12px 0;
            background-color: $base-grey-color;
            resize: none;

            &:focus {
                box-shadow: none;
            }
        }

        .system-default-message {
            color: $elysia-Sky-color;
            font-size: 14px;
            margin-top: 12px;
        }

        p {
            font-size: 18px;
            font-weight: 700;
            color: $informa-indigo;

            &.about {
                font-size: 14px;
                font-weight: 400;
                margin-top: 12px;
                white-space: pre-line;
            }
        }

        .multi-select-dropdown {
            margin-top: 10px;
            position: relative;

            .input-group {
                margin-bottom: 12px;

                .input-icon-container {
                    position: absolute;
                    z-index: 10;
                    left: 15px;
                    top: 9px;

                    path {
                        fill: $informa-grey-color;
                    }
                }

                input.form-control {
                    background-color: $base-grey-color;
                    border-radius: 5px;
                    border: 1px solid $light-grey-color;
                    padding: 8px 12px 8px 45px;
                    line-height: 20px;

                    &:focus {
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: $informa-grey-color
                    }
                }
            }

            .list-group {
                border: 1px solid $light-grey-color;
                border-radius: 0 0 3px 3px;
                padding: 10px 0 0 12px;
                position: absolute;
                width: 100%;
                background-color: white;
                z-index: 10;

                .button-container {
                    border: none;
                    padding-top: 0;
                    margin-bottom: 10px;

                    button {
                        margin-right: 10px;
                        padding: 8px 12px;
                        height: 40px;
                    }
                }

                .list-group-item-container {
                    max-height: 250px;
                    overflow-y: hidden;
                    margin-right: 5px;
                    padding-left: 5px;
                    padding-top: 3px;

                    >div {
                        &::-webkit-scrollbar {
                            width: 4px;
                        }

                        &::-webkit-scrollbar-track {
                            background-color: $primary-white-color;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $light-grey-color;
                            border-radius: 4px;
                        }
                    }

                    .list-group-item {
                        border: none;
                        padding: 0;

                        .form-check {
                            border: none;
                            padding: 0;

                            .form-check-input {
                                height: 1.5em;
                                width: 1.5em;
                                margin: 1px 10px 0 0;

                                &:checked {
                                    background-color: $elysia-Sky-color;
                                    border-color: $elysia-Sky-color;
                                }
                            }
                        }
                    }
                }

                &.open-upwards {
                    bottom: 50px;
                }
            }

        }

        div.badge-container {
            margin-top: 12px;

            .badge {
                color: $informa-indigo;
                font-weight: 400;
                font-size: 14px;
                border: 1px solid $light-grey-color;
                border-radius: 5px;
                margin: 0px 12px 10px 0;
                line-height: 20px;
                cursor: default;

                &.edit {
                    &:hover {
                        color: $informa-grey-color;
                        background-color: $base-grey-color;
                    }
                }

                button {
                    padding: 0px;
                    height: 24px;
                    position: relative;
                    top: -3px;
                    margin-left: 10px;
                }
            }
        }

        div.button-container {
            padding-top: 12px;
            border-top: 1px solid $light-grey-color;

            button {
                padding: 4px 12px;
            }
        }

        div {
            button {
                background: none;
                border: none;
                height: 32px;
                padding: 6px;

                path {
                    fill: $light-grey-color;
                }

                &:hover,
                &:focus,
                &:focus-visible {
                    background: none;
                    border: none;
                }
            }
        }

        img {
            margin-right: 1rem;
            height: 30px;
            width: 30px;
        }

        .profile-row {
            padding: 12px 0px 12px 8px;
            border-bottom: 1px solid #dadada;
            display: flex;
            flex-wrap: wrap;

            div.column-1 {
                width: 30%;
            }

            div.column-2 {
                flex-grow: 1;
            }

            button {
                padding: 0px 6px;
                height: 12px;

                path {
                    fill: $primary-black-color;
                }

                &:hover,
                &:focus,
                &:focus-visible {
                    background: none !important;
                    border: none !important;

                    path {
                        fill: $elysia-Sky-color !important;
                    }
                }
            }

            ;

            div {
                span {
                    float: right;
                    cursor: pointer;
                }
            }
        }

        .form-check {
            padding: 12px 8px 12px 30px;
            border-bottom: 1px solid $light-grey-color;
        }

        .social-selection-container {
            gap: 10px;
            margin-left: 15px;

            button.remove-social {
                width: 24px;
                padding-top: 0;
            }

            span {
                font-weight: 600;
                color: $informa-indigo;
            }

            form {
                display: flex;
                align-items: center;
                gap: 10px;

                >div {
                    flex-grow: 1;
                }

            }

            button.update-profile {
                width: 122px;
            }
        }

        .optin-form {
            .form-check-input {
                border-color: $informa-indigo;

                &:checked {
                    border-color: $informa-indigo !important;
                    background-color: $informa-indigo !important;
                }
            }
        }
    }

    .optin-header {
        gap: 12px;

        button {
            background: none;
            border: none;

            path {
                fill: $light-grey-color;
            }

            &:hover,
            &:focus,
            &:focus-visible {
                background: none;
                border: none;

                path {
                    fill: $elysia-Sky-color
                }
            }
        }
    }

    input[type="text"],
    input[type="checkbox"],
    textarea {

        &:hover,
        &:focus,
        &:focus-visible {
            border-color: $elysia-Sky-color !important;
            box-shadow: none;
        }
    }
}