@import "../../index.scss";

.chat-config-btn {
  border: none;
  background: #ffffff;
  color: #002244;
  margin: 2px 5px;
  font-size: inherit;
  font-weight: 600;
  --bs-btn-active-color: #11a7d9;
  --bs-btn-active-bg: #fff;
  --bs-btn-hover-color: #002244;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
}

.chat-config-btn-popup {
  border-bottom: 1px solid #dadada;
  text-align: left;
}



.upload-file-container {
  width: 30em;
  height: 18em;
  padding: 10px;
}

#form-file-upload {
  height: 12rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  cursor: pointer;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  border: none;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.chat-status-stop-btn {
  background: #11a7d9;
  color: #ffffff;
  border: none;
  font-size: inherit;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #11a7d9;
  --bs-btn-active-border-color: #11a7d9;
  --bs-btn-active-border-style: solid;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #11a7d9;
  --bs-btn-hover-border-color: #11a7d9;
}

.generating-results-status {
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  padding: 3px;
}

.loading-status {
  width: 100%;
  text-align: right;
}

.chat-status-wrapper {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: 0;
  width: 100%;
}

.chat-status {
  color: #dadada;
  line-height: 19px;
  padding: 5px 5px;
}

.chat-input {
  .form-control:focus {
    border-color: #0A7DA4;
  }
}

.chat-input-protection-msg {
  color: $informa-grey-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.chat-input-loading {
  text-align: center;
}

.chat-input-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .text-area-container {
    border-radius: 3px !important;
    background-color: $primary-white-color;
    border: 1px solid #ccc;
  }

  textarea {
    padding: 12px 24px 0px 24px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;

    &::placeholder {
      color: $informa-grey-color;
    }
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: $light-grey-color;
    border-radius: 4px;
    cursor: default;
  }

  textarea::-webkit-scrollbar-thumb:active {
    background-color: $elysia-Sky-color;
  }

  textarea::-webkit-scrollbar-thumb:hover {
    background-color: $elysia-Sky-color;
  }

  .text-area-container:focus-within {
    border: 1px solid $elysia-Sky-color !important;
    border-radius: 3px;
  }

  .promt-input:focus {
    box-shadow: none !important;
  }

  .promt-toolbar-container {
    background-color: $primary-white-color;
    padding: 12px 12px 12px 24px;
  }

  .word-count {
    color: $elysia-Sky-color;
    font-size: 14px;
  }

  .word-count-error {
    color: $elysia-coral;
  }

  .uploaded-file-container {
    display: inline-flex;
    align-items: center;
    background-color: #F8F5FF;
    padding: 8px;
    margin: 12px 0px 0px 24px;
    border-radius: 5px;
    max-width: 33%;
    /* Maximum width for default */
    border: 1px solid #B795FF;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      max-width: 60%;
    }

    @media screen and (min-width: 768px) {
      max-width: 40%;
    }

    @media screen and (min-width: 968px) {
      max-width: 33%
    }

    .uploaded-file-preview {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      width: 100%;
      overflow: hidden;
    }

    .file-info {
      display: flex;
      align-items: top;
      justify-content: space-between;
      gap: 4px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;

      div {
        p {
          margin: 0;
          size: 14px;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .tickIcon {
      width: 24px;
      height: 24px;
      margin-left: 14px;
    }

    .file-status-icon {
      height: 24px;
      width: 24px;
      padding: 8px 8px 24px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // .closeIcon path {
    //   fill: $light-grey-color;
    // }

    .remove-file-btn {
      display: flex;
      justify-content: center;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 4px;
      bottom: 10px;
      margin-left: 4px;
    }

    .file-info-text {
      overflow: hidden;
      max-width: 100%;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }

  .disable-prompt {
    pointer-events: none;
    opacity: 0.7;
  }

  .private-chat,
  .add-sources,
  .saved-prompts,
  .submit-prompt,
  .stop-button,
  .attach-file,
  .chat-widgets {
    background: none;
    border: none;
    width: 36px;
    height: 36px;
    border-radius: 3px !important;
    padding: 4px 5px 6px 6px !important;

    &:not(.stop-button) path {
      fill: $light-grey-color;
    }

    &.stop-button.prompt-input-button {
      border: 1px solid #DADADA;
      width: 38px;
      background-color: #FCFCFC !important;
    }

    &:not(.selected) {
      background-color: transparent !important;
      --bs-btn-active-border-color: transparent;
    }

    @media (pointer: fine) {

      .selected,
      &:hover,
      &:focus:not(.private-chat),
      &:focus-visible {
        background-color: $elysia-Sky-color !important;
        border: 1px solid $elysia-Sky-color !important;
        --bs-btn-active-border-color: #0A7DA4;

        path {
          fill: $primary-white-color !important;
        }
      }
    }

    &.selected,
    &:focus:not(.private-chat, .saved-prompts),
    &:focus-visible {
      background-color: $elysia-Sky-color !important;
      border: 1px solid $elysia-Sky-color !important;
      --bs-btn-active-border-color: #0A7DA4;

      path {
        fill: $primary-white-color !important;
      }
    }

    &.active:not(.stop-button) {
      path {
        fill: $elysia-Sky-color;
      }
    }

    &.stop-button:hover,
    &.stop-button:focus,
    &.stop-button:focus-visible {
      path {
        fill: $elysia-Sky-color !important;
      }
    }

    &.saved-prompts.active,
    &.saved-prompts:focus-visible {
      background-color: $elysia-Sky-color !important;
      border: 1px solid $elysia-Sky-color !important;
      --bs-btn-active-border-color: #0A7DA4;
    }

    &.saved-prompts.active path {
      fill: $primary-white-color !important;
    }

    &.chat-widgets {
      position: absolute !important;
      z-index: 7 !important;
      left: 6px;
      border-right: 1px solid $light-grey-color;
      bottom: 11px;

      &::after {
        display: none !important;
      }

      svg {
        height: 24px;
      }
    }

    &.prompt-input-button {
      background-color: $base-grey-color;
      border-radius: 5px !important;

      &:disabled {
        opacity: unset;

        path {
          fill: $light-grey-color;
        }
      }
    }
  }

  .chat-menu-src-select {
    position: absolute;
    display: block;
    z-index: 9;
    bottom: 45px;
    padding: 24px;
    width: 336px;
    border: 1px solid $light-grey-color;
    border-radius: 5px;
    background: $primary-white-color;
    box-shadow: 0px 4px 4px 0px #0000000D;

    @media (max-width: 767px) {
      width: 314px;
    }

    &:focus-visible {
      outline: none;
    }

    .button-filter-src {
      background: none;
      border: none;
      padding: 0px;
      width: 24px;
      height: 24px;
      z-index: 0;
      position: relative !important;
    }

    select {
      font-size: 14px;
      width: "max-content";

      &:focus {
        border-color: $elysia-Sky-color;
        box-shadow: none;
      }
    }

    .close-data-collection {
      border: none;
      background-color: white;
      height: fit-content;
      --bs-btn-padding-y: 4px;
      z-index: 0;
      position: relative !important;
      top: -7px;

      path {
        fill: $informa-grey-color;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        path {
          fill: $elysia-Sky-color;
        }
      }
    }
  }

  .chat-menu-src-popup {
    position: absolute;
    z-index: 0;
    bottom: 0;
    width: 336px;
    left: 0;
    border: 1px solid $light-grey-color;
    border-radius: 5px;
    background: $primary-white-color;
    box-shadow: 0px 4px 4px 0px #0000000D;

    @media (max-width: 767px) {
      width: 314px;
    }

    .src-list-header {
      padding: 12px 24px 12px 24px;

      .chat-menu-src-select-link {
        background: none;
        border: none;
        padding: 11.5px 8px;

        &:hover,
        &:focus,
        &:focus-visible {
          color: $elysia-Sky-color;
        }
      }

      .search-source-wrapper {
        position: relative;

        .search-source-input {
          border-radius: 3px;
          padding: 6px 20px 6px 38px;

          &:focus {
            border-color: $elysia-Sky-color;
            box-shadow: none;
          }
        }

        .search-icon {
          left: 12px;
          position: absolute;
          top: 7px;

          path {
            fill: $informa-indigo;
          }
        }
      }

      .close-chat-src-popup {
        border: none;
        background-color: white;
        height: fit-content;
        --bs-btn-padding-y: 4px;

        path {
          fill: $informa-grey-color;
        }

        &:hover,
        &:focus,
        &:focus-visible {
          path {
            fill: $elysia-Sky-color;
          }
        }
      }
    }

    .chat-menu-src-list-wrapper {
      padding: 12px 24px 24px 24px;

      .doc-list-wrapper {
        overflow: hidden auto;
        font-weight: 400;
        max-height: 160px;

        input:checked~.icon-toggle-on {
          display: inline !important;
        }

        input:checked~.icon-toggle-off {
          display: none;
        }
      }

      .chat-menu-src-select-back {
        border-radius: 3px;
        border: 1px solid $light-grey-color;
        background: $primary-white-color;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        padding: 6px 12px;
        color: #002244;
        height: 35px;

        svg {
          width: 16px;
          height: 16px;

          path {
            fill: $primary-black-color;
          }
        }
      }

      .chat-menu-src-select-done {
        padding: 6px 12px;
        border-color: $elysia-Sky-color;
      }

      button {
        color: $primary-black-color !important;
      }
    }
  }

  &.private-chat {
    button.saved-prompts {
      display: none;
    }
  }

  &.is-mobile {

    // TODO: see if this can be hidden using bootstrap classes and remove it from here
    button {

      &.private-chat,
      &.add-sources,
      &.saved-prompts,
      &.attach-file {
        display: none;
      }
    }

    textarea {
      padding-left: 14px;
    }
  }

  .dropdown-menu {
    padding: 5px 5px 0 5px;

    button.dropdown-item {
      padding: 0 0 0 5px;
      margin-bottom: 5px;
      height: 30px;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;

        path {
          fill: $light-grey-color;
        }
      }

      &.active {
        color: $elysia-Sky-color !important;

        path {
          fill: $elysia-Sky-color !important;
        }
      }
    }
  }

  .chat-mode-container {
    position: absolute;
    top: -45px;

    >button {
      display: inline !important;
      background: none;
      border: none;
      padding: 4px 5px 6px 6px !important;
      width: 36px;
      height: 36px;

      path {
        fill: $elysia-Sky-color;
      }
    }
  }
}

.content-fixed-top {
  background-color: $base-grey-color;
  padding: 5px 10px;

  @media (min-width: 768px) {
    background-color: $primary-white-color;
    padding: 5px 24px;
  }
}

.content-scrollable {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $primary-white-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-grey-color;
    border-radius: 4px;
  }
}

.content-fixed-bottom {
  margin-top: 8px;

  .scroll-icon-button {
    position: absolute;
    right: 50%;
    transform: translate(50%);
    top: -70px;
    background-color: $primary-white-color;
    border-radius: 9999px;
    padding: 2px;
    width: 32px;
    height: 32px;
    border-color: rgba($informa-indigo, 0.1);

    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $primary-white-color !important;
      border-color: rgba($informa-indigo, 0.1);
    }
  }
}


.width-control {
  width: 66%;

  .private-chat-title {
    color: $elysia-Sky-color;
  }

  .private-chat-subtitle {
    color: $informa-grey-color;
  }
}

.chat-interface .width-control.landing-view {
  width: 85.76%;

  @media (min-width: 768px) {
    width: 624px;
  }

  @media (min-width: 960px) {
    width: 624px;
  }

  @media (min-width: 1260px) {
    width: 624px;
  }
}

.chat-interface .width-control-bottom,
.chat-interface .width-control:not(.landing-view),
.profile-page .width-control,
.notifications-page .width-control {
  width: 86.67%;

  @media (min-width: 768px) {
    width: 624px;
  }

  @media (min-width: 960px) {
    width: 834px;
  }

  @media (min-width: 1260px) {
    width: 834px;
  }

  @media (min-width: 1440px) {
    width: 834px;
  }

  @media (min-width: 1920px) {
    width: 834px;
  }
}

.chat-interface .width-control {
  position: relative;
  left: 5px;
}


.chat-input-button-hide {
  display: none;
  transition: opacity 200ms, display 200ms;
  opacity: 0;
}

.chat-input-button-show {
  display: block;
}

.chat-button {
  background: #11a7d9;
  margin: 0 10px 0 0;
  height: 45px;
  border: none;
  font-size: inherit;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #11a7d9;
  --bs-btn-active-border-color: #11a7d9;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #11a7d9;
  --bs-btn-hover-border-color: #11a7d9;
}

.chat-button>img:hover {
  background: #47355d;
}

.chat-button>span:hover {
  background: #47355d;
}

.chat-icon {
  position: relative;
  right: 30px;
  top: 50px;
  z-index: 9999;
  color: #525a5c;
  cursor: pointer;
}

.user-msg-body {
  max-width: 85%;
  float: left;
  display: flex;
  align-items: baseline;

  .custom-card-body {
    --bs-card-spacer-x: 8px;
    --bs-card-spacer-y: 8px;
  }

  .custom-card {
    --bs-card-border-color: white;
    --bs-border-radius: 5px !important;
  }
}

.sys-msg-body {
  width: 100%;
  display: block;
  float: left;
}

.landing-view {
  .sys-msg-body {
    min-width: 60%;
    max-width: 100%;
    width: unset;
  }
}

.sys-protection-msg {
  margin: 0 0 24px 24px;
}

.sys-msg-card {
  border-color: #dadada;
  overflow: visible;
  position: relative;
}

.sys-msg-card-img {
  height: 24px;
  top: 3px;
  left: 5px;
}

.sys-msg-top-options {
  margin: 0px 5px;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 5px;
}

.sys-msg-card-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-left: 10px;
  color: $primary-black-color;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.sys-msg-card-elysia {
  color: $elysia-Sky-color;
  font-family: var(--font-aleo);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
  padding-left: 5px;
}

.sys-msg-card-interactions {
  border-top: 1px solid #DADADA;
  padding: 12px 24px;

  .feedback-text {
    color: $informa-indigo;
    font-size: 18px;
    font-weight: 700;
  }
}

.sys-msg-card-desc {
  font-weight: 400;
  font-size: 16px;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $primary-white-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-grey-color;
    border-radius: 4px;
  }

  .markdown-table-container {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: $primary-white-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $light-grey-color;
      border-radius: 4px;
    }

    .markdown-table {
      width: 100%;
      border-collapse: collapse;
      margin: 20px 0;
      font-size: 16px;

      td {
        border: 1px solid $light-grey-color;
        padding: 8px;
      }

      th {
        padding: 8px;
        border: 1px solid $base-grey-color;
        background-color: $light-grey-color;
        font-weight: bold;
        text-align: left;
      }

      tr:hover {
        background-color: $base-grey-color;
      }
    }
  }

  code.text-break-space {
    white-space: break-spaces;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 1rem;
  }
}

.user-msg-card-desc {
  color: $informa-indigo;
  font-size: 16px;
  display: flex;
  align-items: end;
  white-space: pre-line;
  gap: 24px;

  .saved-prompt-bookmark-icon {
    --bs-btn-bg: #FCFCFC;
    --bs-btn-border-color: #DADADA;
    border-radius: 3px;
    padding: 0px 3px 0px 3px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;

    &:hover,
    &:focus-visible {
      background-color: $elysia-Sky-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;

      path {
        fill: $primary-white-color;
      }
    }
  }

}

.save-prompt-tooltip {
  .tooltip-inner {
    background-color: $elysia-Sky-color;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
  }

  .tooltip-arrow {
    display: none;
  }

}

.history-badge-for-saved-prompt-button {
  transform: translateX(30%);
  color: #525A5C;
  display: inline-block;
  bottom: -25px;
  width: max-content;
  right: 0px;
  background-color: #F8F5FF;
  font-size: 12px;
  padding: 4px 8px;
  animation: fadeIn 1s linear;
  border-radius: 5px;

  span {
    margin-left: 5px;
  }
}

.sys-msg-next-prompts {
  --bs-bg-opacity: 0;
  background-color: #FFFFFF;
}

.sys-msg-prompts {
  text-align: right;
  margin-top: 10px;
}


.sys-msg-sources {
  border-top: 1px solid $light-grey-color;
  font-size: 16px;
  padding: 10px 10px;
}

.sys-msg-sources-body {
  display: flex;
  flex-wrap: wrap;
}

.sys-msg-sources-card {
  margin: 3px 3px;
}

.sys-msg-sources-label,
.sys-card-sources-body {
  margin: 3px 3px;
  padding: 5px 5px;
}

.sys-card-body {
  padding: 5px 10px;
  background: #11a7d9;
  color: #fff;
}

.custom-sys-card-body {
  --bs-card-spacer-x: 24px;
  --bs-card-spacer-y: 24px;

  @media (max-width: 767px) {
    --bs-card-spacer-x: 18px;
    --bs-card-spacer-y: 18px;
  }
}

.sys-msg-card:hover {
  --bs-btn-active-border-color: #0A7DA4;
  border: 1px solid $elysia-Sky-color;
}

.unselected-chat-action-button {
  --bs-btn-bg: white;
  --bs-btn-border-color: #DADADA;
  padding: 0px 3px 0px 3px;
  border-radius: 3px;
  height: 24px;
  width: 24px;
  justify-content: center;
  display: inline-flex;

  &:focus-visible,
  &:hover {
    background-color: $elysia-Sky-color;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;

    path {
      fill: $primary-white-color;
    }
  }

  &:focus {
    --bs-btn-active-bg: #0A7DA4;
  }
}

.feedback-line {
  font-size: 16px;
  color: $informa-indigo;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.history-badge-for-copy-button {
  transform: translateX(0%);
  color: #525A5C;
  display: inline-block;
  right: 0px;
  background-color: #F8F5FF;
  font-size: 12px;
  padding: 4px 8px;
  animation: fadeIn 1s linear;
  border-radius: 5px;

  span {
    margin-left: 5px;
  }
}

.chat-action-button {
  --bs-btn-bg: #FCFCFC;
  --bs-btn-border-color: #FCFCFC;
  border-radius: 3px;
  padding: 0px 3px 0px 3px;
  height: 24px;
  width: 24px;
  justify-content: center;
  display: inline-flex;

  path {
    fill: $elysia-Sky-color;
  }

  &:focus {
    --bs-btn-active-bg: #0A7DA4;
  }

  &:hover,
  &:focus-visible {
    background-color: $elysia-Sky-color;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;

    path {
      fill: $primary-white-color;
    }
  }


}

.user-icon-display {
  border: 1px solid $elysia-brand-blue;
  background-color: #FCFCFC;
  padding: 4px;
  border-radius: 5px;
  color: $informa-indigo;

  .user-initials-display {
    justify-content: center;
    font-family: var(--font-aleo);
    font-weight: 700;
    display: flex;
    font-size: 20px;
  }
}

.custom-backdrop {
  background-color: rgba(255, 255, 255, 0.8);
}


.save-prompt-modal {

  .modal-header {
    --bs-modal-header-border-width: 0px;
  }

  .modal-header>.btn-close {
    --bs-btn-close-bg: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"%3E%3Cpath d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="%23525A5C"/%3E%3C/svg%3E');

    &:hover {
      --bs-btn-close-bg: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"%3E%3Cpath d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="%230A7DA4"/%3E%3C/svg%3E');
    }
  }

  .custom-modal {
    margin: initial !important;
  }

  .custom-modal>.modal-content {
    border: 1px solid $elysia-Sky-color;
    border-radius: 5px;
  }

  .modal-title {
    font-weight: 700;
    font-size: 18px;
  }

  .search-bar-container {
    margin-bottom: 12px;
  }

  .saved-topic-container {
    height: 216px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: $primary-white-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $light-grey-color;
      border-radius: 4px;
    }
  }

  .saved-topic-inner-conatiner {
    display: flex;
    flex-flow: wrap;
  }

  .no-results-message {
    margin: auto;
    height: 216px;
    align-items: center;
    display: flex;
  }

  .saved-prompt-topic {
    width: calc(50% - 6px);
    margin: 0px 2px 12px 2px;
    padding: 8px;
    height: 96px;
    background: $primary-white-color;
    border-radius: 5px;
    border: 1px solid #DADADA;

    @media (max-width: 767px) {
      width: 100%;
    }

    &:hover {
      .save-topic-icon {
        path {
          fill: $elysia-Sky-color;
        }
      }

      border: 1px solid $elysia-Sky-color;
    }

    &:focus,
    &:focus-visible {
      background-color: $primary-white-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;
    }
  }

  .saved-topic-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-align: left;
    -webkit-box-orient: vertical;
    color: $primary-black-color;
    width: 85%;
  }

  .save-topic-icon {
    margin-top: 4px;

    &:hover {
      path {
        fill: $elysia-Sky-color;
      }
    }
  }

  .saved-prompt-text-container {
    height: 46px;
  }

  .search-text {
    border-radius: 3px;
    padding-left: 32px;
  }

  .search-saved-topic-button {
    position: absolute;
    background: transparent;
    border: none;
    border-right: 0px;
    height: 34px;
    padding: 7px 0px 0px 8px;
    border-radius: 3px;
    z-index: 100;

    path {
      fill: $informa-indigo;
    }
  }

  .delete-saved-topic-icon {
    background-color: $primary-white-color;
    background-color: #FFFFFF;
    --bs-btn-bg: white;
    --bs-btn-border-color: white;
    padding: 0px 0px 0px 0px;
    height: 18px;
    justify-content: center;
    display: inline-flex;
    --bs-btn-disabled-border-color: #ffffff;

    &:focus-visible,
    &:hover {
      background-color: $primary-white-color;
      border: 1px solid $primary-white-color;
      --bs-btn-active-border-color: #ffffff;

      path {
        fill: $elysia-Sky-color;
      }
    }

    &:focus {
      --bs-btn-active-bg: #ffffff;
    }
  }

  .delete-saved-topic {
    text-align: end;

    path {
      fill: $light-grey-color
    }
  }

  .saved-prompt-topic:focus,
  .saved-prompt-topic:active {
    background-color: $primary-white-color;
    border: 1px solid $elysia-Sky-color;
    outline: none;
    box-shadow: none;
  }
}

.history-badge-delete-prompt-button {
  margin-top: 4px;
  color: $informa-grey-color;
  background-color: #F8F5FF;
  font-size: 12px;
  padding: 4px 8px;
  animation: fadeIn 1s linear;
  border-radius: 5px;
  float: right;
  position: relative;

  span {
    margin-left: 5px;
  }
}

.user-message-body {
  width: calc(100% - 48px);
}

.feedback-for-responses {
  width: 90%;
  padding: 24px;
  margin: 12px auto;
  border-radius: 5px;
  border: 1px solid $elysia-Sky-color;
  background-color: $primary-white-color;

  .feedback-header {
    display: flex;
    justify-content: space-between;
  }


  .feedback-message {
    display: flex;
    margin: 12px 0px;
    align-items: center;
  }

  .feedback-content-text {
    font-size: 14px;
    margin-left: 16px;
  }

  .inputForFeedback {
    height: 54px;
    background: $base-grey-color;
    border: 1px solid $elysia-Sky-color;
    outline: none;
    box-shadow: none;
    margin-bottom: 24px;

    &:focus,
    &:focus-visible {
      background: $base-grey-color;
      border: 1px solid $elysia-Sky-color;
      outline: none;
      box-shadow: none;
    }
  }

  .close-feedback {
    background-color: $primary-white-color;
    border: none;
    padding: 0px;
    height: auto;

    &:hover,
    &:focus,
    &:focus-visible {
      --bs-btn-active-border-color: none;
      --bs-btn-active-bg: #FFFFFF;

      path {
        fill: $elysia-Sky-color
      }
    }
  }

  .submit-button {
    display: inline-flex;
    background-color: $base-grey-color;
    border: 1px solid $light-grey-color;
    --bs-btn-padding-x: 24px;
    --bs-btn-padding-y: 8px;
    border-radius: 3px;

    &:hover,
    &:focus,
    &:focus-visible {
      --bs-btn-active-border-color: #DADADA;
      --bs-btn-active-bg: #FCFCFC;
      border: 1px solid $elysia-Sky-color;

      .submit-text {
        color: $elysia-Sky-color;
      }
    }
  }

  .submit-text {
    text-decoration: underline;
    color: $informa-indigo;
    font-weight: 600;
  }
}

.source-interaction-btn {
  background: none;
  color: $informa-indigo;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;

  &:hover {
    color: $elysia-blue
  }

  &:focus-visible {
    outline: solid 2px;
    outline-offset: 4px;
  }
}

.source-list-item {
  padding: 16px;
  color: $informa-indigo;

  &:focus,
  &:focus-visible,
  &:hover {
    border: var(--bs-list-group-border-width) solid $elysia-Sky-color;
  }
}

.source-img {
  width: 24px;
  height: 24px;
}

.source-item {
  margin-left: 10px;
  font-size: 12px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.source-item-container {
  width: 95%;
}

/* Keyframes to animate the skeleton */
@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.searching-placeholder {
  background: linear-gradient(90deg, #474444 15%, #b5acac 35%, #656161 75%, #a5a0a0 90%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 10px;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-size: 400%;
  animation: move 20s ease infinite;
  height: 24px;
}

@keyframes move {

  0%,
  100% {
    background-position: 200% 0%;
  }

  50% {
    background-position: 0% 200%;
  }
}


////////

@media only screen and (max-width: 360px) {
  .chat-config-btn {
    padding: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .chat-config-btn {
    padding: 4px;
  }

}