@import "../../index.scss";

.header-container {
  display: flex;
  margin-top: 0;

  @media (min-width: 768px) {
    margin-top: 42px;
  }
}

.header-content {
  align-self: flex-end;
  font-weight: 700;
  font-family: var(--font-aleo);
}

.header-greeting {
  font-size: 20px;
  color: $elysia-Sky-color;
  line-height: 24px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 40px;
  }
}

.header-greeting-1 {
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.header-welcome-mesg {
  font-family: var(--font-aleo);
  font-size: 48px;
  color: #002244;
  line-height: 57.6px;
  padding: 6px 0;
}

.header-sub-title {
  font-size: 18px;
  color: #002244;
  line-height: 24.51px;
  padding: 6px 0;
}

.header-text {
  font-weight: 400;
  line-height: 20px;
}

.header-btn-grp {
  .user-name {
    white-space: nowrap;
    max-width: 7vw;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      padding-left: 8px;
    }
  }


  .btn {
    border: none !important;
    cursor: pointer;
  }

  .dropdown-menu {
    text-align: left;
    font-size: 14px;
    top: 38px;
    left: 50px;

    a {
      padding: 7px;
      border-bottom: 1px solid #DADADA;
    }
  }

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.chat-menu-src-select,
.chat-menu-search-select {
  border-top: 1px solid #DADADA;
  padding: 8px;
  display: none;
  color: #024;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.chat-menu-src-option,
.chat-menu-search-option {
  padding: 8px;
  font-weight: 400;
  margin-top: 12px;

}

.chat-menu-src-select-link {
  text-decoration: none;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  color: #525A5C;
  cursor: pointer;
}

.chat-menu-src-select-done {
  border-radius: 3px;
  border: 1px solid var(--INFORMA-Blue, #0A7DA4);
  background: var(--INFORMA-White, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 6px;
  color: #002244;
  height: 35px;
}

.doc-list-wrapper {
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 19px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    &:focus-within svg {
      border: solid 4px $elysia-blue;
    }
  }

  span.disabled {
    color: $light-grey-color;
  }
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadada;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  top: 3px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #0A7DA4;
}

input:focus+.slider {
  box-shadow: 0 0 1px #0A7DA4;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}


.profile-menu {
  .dropdown-toggle::after {
    display: none !important;
  }

  a.dropdown-item {
    cursor: pointer;
  }
}

.icon-header-menu-button {
  display: inline-flex;
  background-color: #FCFCFC;
  border: 1px solid #DADADA;
  height: 48px;
  width: 48px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;

  @media (pointer: fine) {
    &:hover,
    &:focus-visible {
      background-color: $elysia-Sky-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;

      path {
        fill: $primary-white-color;
      }
    }
  }

  &:focus-visible {
    background-color: $elysia-Sky-color;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;

    path {
      fill: $primary-white-color;
    }
  }

  &:focus {
    --bs-btn-active-bg: #0A7DA4;
  }
}

.icon-header-help-menu-button {
  display: inline-flex;
  background-color: #FCFCFC;
  border: 1px solid #DADADA;
  height: 48px;
  width: 48px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;

  @media (pointer: fine) {
    &:hover,
    &:focus-visible,
    &:focus {
      background-color: $elysia-Sky-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;
      --bs-btn-active-bg: #0A7DA4;

      path {
        fill: $primary-white-color;
      }
    }
  }  
  
  &:focus-visible,
  &:focus {
    background-color: $elysia-Sky-color;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;
    --bs-btn-active-bg: #0A7DA4;

    path {
      fill: $primary-white-color;
    }
  }
}

.user-name-icon {
  border: 1px solid $elysia-brand-blue;
  background-color: $base-grey-color;
  height: 48px;
  width: 48px;
  padding: initial;
  border-radius: 3px;
  color: $informa-indigo;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (pointer: fine) {
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $elysia-brand-blue;
      border: 1px solid $elysia-brand-blue;
      --bs-btn-active-bg: #28B4FF;
      --bs-btn-active-border-color: #28B4FF;
      color: $primary-white-color;
      align-items: center;
      justify-content: center;
      text-decoration: underline;
    }
  }
  
  &:focus,
  &:focus-visible {
    background-color: $elysia-brand-blue;
    border: 1px solid $elysia-brand-blue;
    --bs-btn-active-bg: #28B4FF;
    --bs-btn-active-border-color: #28B4FF;
    color: $primary-white-color;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
  }

  .notification-dot {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    width: 12px;
    height: 12px;
    background-color: $elysia-coral;
    border-radius: 50%;
    border: 2px solid $primary-white-color;
    display: block;
  }

}


.logout-dropdown-open-state {
  --bs-btn-active-bg: #28B4FF;
  border: 1px solid $elysia-brand-blue;
  --bs-btn-active-border-color: #28B4FF;
  color: $primary-white-color;
}

.user-name-initials {
  font-family: var(--font-aleo);
  font-weight: 700;
  font-size: 20px;
}

.dropdown-open-state {
  --bs-btn-active-bg: #0A7DA4;
  border: 1px solid $elysia-Sky-color;
  --bs-btn-active-border-color: #0A7DA4;

  path {
    fill: $primary-white-color;
  }
}


.custom-tooltip {
  position: fixed !important;
  .tooltip-inner {
    background-color: $elysia-Sky-color;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 28px;
    @media (pointer: coarse) {
      display: none;
    }
  }
  
  .tooltip-arrow {
    display: none;
  }
}

.dropdown-item-anchor-button:hover {
  border: 1px solid #DADADA;
  border-width: 1px 0px;
}

.dropdown-item-text {
  font-size: 14px;

  .notification-dot {
    content: "";
    background-color: $elysia-coral;
    border-radius: 50%;
    border: 2px solid $primary-white-color;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
}

.dropdown-item-icon {
  width: 18px;

  svg {
    path {
      fill: $informa-indigo;
    }
  }
}


.sample-prompt-card {
  --bs-card-spacer-y: 12px;
  width: calc(50% - 6px);
  padding: 12px 8px 12px 16px;
  height: 66px;
  background: $primary-white-color;
  border-radius: 5px;
  border: 1px solid $light-grey-color;

  @media (max-width: 767px) {
    width: 100%;
  }

  .sample-prompt-text {
    font-size: 14px;
    overflow: hidden;
    width: 85%;
    margin-left: 12px;
    display: -webkit-box;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: $primary-black-color;
  }


  .sample-prompt-card-body {
    height: 42px;
  }

  .chat-save-icon {
    margin-top: 4px;
  }

  .sample-prompt-header {
    font-size: 14px;
    margin-left: 10px;
    color: $informa-grey-color;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: $primary-white-color;
    --bs-card-border-color: #0A7DA4;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;
    --bs-btn-active-bg: white;

    path {
      fill: $elysia-Sky-color;
    }

    .sample-prompt-header {
      color: #0A7DA4 !important;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    background-color: $primary-white-color;
    border: 1px solid $light-grey-color;
    pointer-events: none;
  }
}

@media only screen and (max-width: 360px) {

  .header-title,
  .header-welcome-mesg,
  .header-sub-title {
    text-align: center;
  }

  .header-text {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .header-title,
  .header-welcome-mesg,
  .header-sub-title {
    text-align: center;
  }

  .header-text {
    display: none;
  }

  .header-container {
    min-height: 0;
    display: block;
    padding-left: 8px;
  }
}