.sandboxContainer {
    display: flex;
    margin-left: 12px;
    height: 86%; 

    .sys-msg-body{
        max-width: 90%;
    }


    .settingsColumn {
        flex: 0 0 25%; 
        overflow-y: auto;
        border-right: 1px solid lightgrey;
    }
    
    .sandboxChatContainer {
        flex: 1; 
        display: flex;
        flex-direction: column;
        padding-left: 24px;
    }
    
    .chatboxcontainer {
        flex: 1; 
        overflow-y: auto;
    }
    
    .setting-options-column{
        padding: 8px 8px 8px 0px;
    }
    
    .chat-input {
        flex-shrink: 0; 
        padding: 10px 0; 
        width: 80%;
    
        .input-for-sandbox{
            resize: none;
        }
    }
}