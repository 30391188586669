@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");

$elysia-Sky-color: #0A7DA4;
$elysia-blue: #11A0d0;
$elysia-brand-blue: #28B4FF;
$primary-white-color: #FFFFFF;
$base-grey-color: #FCFCFC;
$primary-black-color: #000000;
$light-grey-color: #DADADA;
$informa-grey-color: #525A5C;
$informa-indigo: #002244;
$elysia-lavender: #B795ff;
$elysia-coral: #DF5A82;

:root {
  --font-sans: 'Open Sans', Arial, 'Helvetica Neue', sans-serif;
  --font-aleo: 'Aleo';
}

body {
  font-family: var(--font-sans);
  font-size: 14px;
  font-weight: 400;
  color: $informa-indigo;
}

main {
  min-height: 82vh;
}

.sys-badge {
  --bs-bg-opacity: 0;
  float: left !important;
  margin: 0 !important;
}

.mandatory {
  color: red;
}

.gap {
  line-height: 24px;
}

.loading {
  text-align: left;
  padding: 10px
}

button {
  border-radius: 3px;
  background: var(--INFORMA-Blue, #0A7DA4);
  color: var(--INFORMA-White, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 128.571% */
  height: 44px;
  padding: 8px 12px;
  align-items: center;
  border: 0;
}

button.color-elysia-sky {
  background: $elysia-Sky-color;
}

button.light-with-border {
  background: $base-grey-color !important;
  border: 1px solid $elysia-Sky-color !important;
  border-radius: 3px !important;
  color: $informa-indigo !important;
  padding: 6px 8px 6px 8px;
  font-size: 14px;
  font-weight: 600;

  &.btn-primary {

    &:hover,
    &:focus,
    &:focus-visible {
      color: $elysia-Sky-color !important;
    }
  }

  &.btn-secondary {
    border: 1px solid $light-grey-color !important;

    &:hover,
    &:focus,
    &:focus-visible {
      color: $elysia-Sky-color !important;
      border: 1px solid $elysia-Sky-color !important;
      box-shadow: 0px 4px 4px 0px #00000008;
    }
  }
}

button.light-without-border {
  background: $base-grey-color !important;
  border-radius: 3px !important;
  color: $informa-grey-color !important;
  padding: 6px 8px 6px 8px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid $base-grey-color !important;

  &.btn-primary {

    &:hover,
    &:focus,
    &:focus-visible {
      border: 1px solid $light-grey-color !important;
      color: $informa-indigo !important;
    }
  }

}

.system-info-msg {
  color: $elysia-Sky-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 24px 0px;

}

.color-primary {
  color: #0A7DA4
}

.color-secondary {
  color: #002244
}

.color-col7 {
  color: #DADADA
}

//Typographies
.title1 {
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0;
  @media screen and (max-width: 380px) {
    font-size: 24px;
  }
}

.title2 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
}

h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0;
}

h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0;
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
}

h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
}

h6 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.p1 {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
}

.p2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
}

.p3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
}

.p4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.p-header {
  font-weight: 600;
}

.p5 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.sys-msg-card-desc {
  h1 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 25px;
  }

  h2, h3, h4, h5, h6 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}


.gemini-logo {
  height: 2rem;
}

.container-fluid.container-sm,
.container-md.container-lg {
  width: 50%;
  padding-right: 2rem;
  padding-left: 10rem;
  margin-right: auto;
  margin-left: auto;
}

.form-control {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: none;
  --bs-dropdown-link-active-color: #212529;
  --bs-dropdown-spacer: 6px
}

.center {
  text-align: center;
  margin-top: calc(100vh / 4);
}

.container {
  max-width: none;
}


.confugrations-table {
  border: 1px solid $elysia-Sky-color;
  border-radius: 5px;
  padding: 24px;
  margin-bottom: 20px;
  background: white;
  box-shadow: 0px 4px 4px 0px #0000000D;

  .prompt-and-responses-text {
    font-size: 18px;
    font-weight: 700;
    padding: 8px 0px 18px 0px;
    color: #002244;
  }

  .custom-table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0px;
  }

  .table-data {
    padding-top: 16px;
    color: $informa-indigo;
  }

  .width-column {
    width: 32%;
  }

  .horizontal-line {
    border-top: 1px solid $light-grey-color;
    margin: 0;
    opacity: 1;
  }

  .custom-table td,
  .custom-table th {
    border: none;
    border-bottom: 1px solid $light-grey-color;
  }

  .custom-table tbody tr {
    border-bottom: 1px solid $light-grey-color;
  }

  .icon-align {
    text-align: end;
  }

  .custom-table tbody tr:hover {
    --bs-table-hover-bg: #F8F9FA;

    .forward-arrow-icon {
      background-color: #F8F9FA;
    }

  }

  .forward-arrow-icon {
    border: none;
    background-color: white;
    height: fit-content;
    --bs-btn-padding-y: 4px;

    &:hover,
    &:focus,
    &:focus-visible {
      path {
        fill: $elysia-Sky-color;
      }
    }
  }
}

.backwards-icon {
  background: $primary-white-color;
  border: none;
  height: 48px;

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: $primary-white-color;
    --bs-btn-active-bg: white;

    path {
      fill: $elysia-Sky-color;
    }
  }
}

.custom-radio {
  display: flex;
  align-items: flex-end;
  padding: 12px;
  margin-bottom: 0px;

  &:hover {
    background-color: #F8F9FA;

    .form-check-input {
      border: 0px solid $elysia-Sky-color;
      --bs-form-check-bg-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="%230A7DA4"/%3E%3C/svg%3E');
    }

  }
}

.custom-radio label {
  color: $informa-indigo;
}

.custom-radio>.form-check-input {
  width: 20px;
  margin-right: 10px;
  margin-left: -5px;
  height: 20px;
  --bs-form-check-bg-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="%23002244"/%3E%3C/svg%3E');
  border: 0px solid $informa-indigo;

  &:hover {
    border: 0px solid $elysia-Sky-color;
    --bs-form-check-bg-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"%3E%3Cpath d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" fill="%230A7DA4"/%3E%3C/svg%3E');
  }
}

.custom-radio>.form-check-input:checked[type=radio] {
  margin-right: 10px;
  margin-left: -5px;
  --bs-form-check-bg-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="2 2 20 20" fill="none"%3E%3Ccircle cx="12" cy="12" r="4" fill="black" /%3E%3Ccircle cx="12" cy="12" r="8.5" stroke="black" /%3E%3C/svg%3E');
  background-color: $primary-white-color;

  &:focus,
  &:focus-visible {
    box-shadow: none;
  }

}

.skip-link {
  position: absolute;
  top: -100px;
  left: 50%;
  background-color: $elysia-Sky-color;
  color: #fff;
  padding: 8px 16px;
  z-index: 9999;
  text-decoration: none;
  transform: translateX(-50%);
  outline: none;

  &:focus {
    top: 10px;
  }

  &:focus:not(:focus-visible) {
    top: -100px;
  }
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0A7DA4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0A7DA4;
}


/* Toast customisation START */

.Toastify__toast-container {
  --toastify-toast-width: 624px;
  --toastify-toast-min-height: 40px;
  --toastify-color-light: #F8F5FF;
  --toastify-text-color-light: #002244;
  --toastify-font-family: var(--font-sans);
  padding: 0;
  position: absolute;
  margin-top: 5px;

  @media (min-width: 768px) {
    --toastify-toast-width: 66%;
    margin-left: -6px;
    margin-top: 0px;
  }

  @media (min-width: 960px) {
    --toastify-toast-width: 500px;
  }

  @media (min-width: 1260px) {
    --toastify-toast-width: 624px;
  }

  @media (min-width: 1440px) {
    --toastify-toast-width: 624px;
  }

  @media (min-width: 1920px) {
    --toastify-toast-width: 800px;
  }


  .Toastify__toast {
    border: 1px solid $elysia-lavender;
    border-radius: 5px;
    margin-bottom: 0;
    box-shadow: none;

    .Toastify__toast-body {
      padding: 0;

      .custom-toast-container {
        padding-left: 8px;

        >svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        span {

          a {
            font-weight: bold;
            color: inherit;
          }
        }

        button {
          background: none;
          border: none;
          height: unset;
          padding: 0 8px;
          position: relative;
          top: -2px;

          &:focus,
          &:hover,
          &:focus-visible {
            background: none;
            border: none;
          }

          path {
            fill: $light-grey-color !important;
          }
        }
      }
    }

    .Toastify__close-button {
      height: 24px;

      svg {
        width: 18px;
        height: 24px;
      }

      path {
        fill: $light-grey-color
      }
    }
  }
}

/* Toast customisation END */

/* Modal customisation START */

.elysia-modal {
  --bs-modal-header-padding: 0 0;
  --bs-modal-padding: 12px 0;
  --bs-modal-width: 576px;
  --bs-modal-border-color: $elysia-Sky-color;

  .modal-content {
    padding: 24px;

    .modal-header {
      border-bottom: 0;

      .modal-title {
        font-size: 18px;
        font-weight: 700;
        color: $informa-indigo;
      }
    }

    .modal-body {
      p {
        margin-bottom: 12px;
        color: $informa-indigo;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          margin-right: 10px;
          height: 30px;
          width: 30px;
        }
      }
    }

    .modal-footer {
      padding: 12px 0 0 0 !important;

      button {
        margin: 0;
        height: 40px;
      }
    }
  }
}

/* Modal customisation END */

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;  
}