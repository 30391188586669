@import "../../index.scss";

.notifications-page {
    .my-notification-title {
        color: $informa-indigo;
    }

    button.back-to-notifcations {
        border: none;
        background: none;
        margin-right: 12px;
    }

    .menu-option-box {
        border: 1px solid $elysia-Sky-color;
        border-radius: 5px;
        background-color: $primary-white-color;
        box-shadow: 0px 4px 4px 0px #0000000d;

        button {
            background: none;
            border: none;
            text-align: unset;
            color: $informa-indigo;
        }

        .notification-row {
            padding: 12px 0px 12px 8px;
            border-bottom: 1px solid $light-grey-color;


            .notification-title {
                font-weight: 400;

                &.unread {
                    font-weight: 700;

                    .indicator {
                        content: "";
                        height: 10px;
                        width: 10px;
                        background-color: $elysia-brand-blue;
                        border-radius: 50%;
                        border: 2px solid white;
                        display: inline-block;
                        margin-right: 5px;
                    }
                }
            }


            .notification-info-row {
                font-weight: 400;
                margin-bottom: 5px;
            }
        }

        &.notification-detail {
            .notification-row {
                border-bottom: 0;

                .notification-info-row {
                    border-bottom: 1px solid $light-grey-color;
                    padding-bottom: 5px;
                }

                .notification-title {
                    font-weight: 700;
                    font-size: 20px;
                    margin: 15px 0;
                }
            }

            .notification-body {
                pre {
                    white-space: pre-wrap !important;
                    font-family: var(--font-sans);
                    font-size: inherit;

                    code {
                        font-family: var(--font-sans);
                    }
                }
            }
        }
    }
}
