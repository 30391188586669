@import "../../index.scss";

.nav-box {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin: 14px 0;
  gap: 12px;

  @media (min-width: 768px) {
    margin: 24px 5px;
  }
}

.prompt-container {
  width: 50%;
}

.panel-container {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.left-panel {
  border-right: 1px solid #DADADA;
  padding-top: 26px;
  padding-left: 24px;
  padding-bottom: 10px;
  position: relative;
  width: 90px;

  .history-icon-wrapper {
    padding: 8px;
    border: 1px solid #DADADA;
    border-radius: 3px;
    margin-bottom: 12px;
  }

  .history-icon-wrapper,
  .new-chat-button {
    background-color: $base-grey-color;

    &:hover,
    &:focus-visible {
      background-color: $elysia-Sky-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;
      color: #FFF;

      path {
        fill: #FFF;
      }
    }
  }

  .new-chat-button {
    width: 230px;
    max-width: 90%;
    font-size: 14px;
    border: 1px solid #DADADA;
    border-radius: 3px;
    padding: 8px;
    color: #000000;
    text-align: left;
    transition: width 300ms;

    span {
      margin-left: 10px;
    }

    path {
      fill: #000000;
    }
  }

  .back-icon-button {
    border: 1px solid #DADADA;
    border-radius: 3px;
    padding: 8px 8px 8px 8px;
    background: #FCFCFC;
    border-right: 1px solid #fff;
    position: relative;
    left: 2px;

    path {
      fill: #000000;
    }

    &:hover,
    &:focus-visible,
    &:focus {
      background-color: $elysia-Sky-color;
      border: 1px solid $elysia-Sky-color;
      --bs-btn-active-border-color: #0A7DA4;

      path {
        fill: #FFF;
      }
    }
  }

  .chat-history-title-wrapper {
    margin-top: 2px;
    padding: 8px 0px 0px 8px;
    color: $informa-grey-color;
  }

  .private-chat-mode {
    opacity: 0.2;
    pointer-events: none;
  }

  .menu-scrollable {
    margin: 24px 5px 12px 0;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: $primary-white-color;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $light-grey-color;
      border-radius: 4px;
    }
  }

  .archive-msg {
    color: $informa-grey-color;
    font-size: 12px;
  }

}

.panel {
  transition: width 200ms;
}

.sidebar-collapse {
  @media (max-width: 991px) {
    .left-panel {
      width: 0;
      padding: 0;

      .btn-container {
        visibility: hidden;
      }
    }

    .right-panel {
      transition: none;
    }
  }
}

.sidebar-expand {
  .left-panel {
    width: 304px;

    @media (max-width: 1259px) {
      padding-left: 24px;
      position: absolute;
      z-index: 101;
      height: 100vh;
      background-color: $primary-white-color;
    }

    .right-panel {
      @media (max-width: 1259px) {
        transition: none;
      }
    }
  }

  .open-sidebar-mobile {
    display: none !important;
  }
}

.right-panel {
  width: calc(100% - 90px);
  background-color: $base-grey-color;
  position: relative;

  .active-history-loading {
    position: relative;
    height: 100vh;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
    }
  }
}

.sidebar-expand {
  .right-panel {
    width: calc(100% - 304px);
  }
}

.custom-accordion {
  --bs-accordion-border-color: white;
  --bs-accordion-active-bg: white;
  --bs-accordion-btn-padding-x: 0.5rem;
  --bs-accordion-btn-padding-y: 0;

  .accordion-item {
    margin-bottom: 12px
  }

  .accordion-header {
    margin-bottom: 3px;
  }

  .custom-accordion-header>.accordion-button {
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    height: 24px;
  }

  .accordion-item>.accordion-header .accordion-button {
    --bs-accordion-inner-border-radius: 0px;
    width: fit-content;
    padding-right: 40px;
  }

  .accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow: 0px 0px 1px 0px #0A7DA4;
    border-radius: 0px;
  }

  .accordion-button:hover {
    .custom-accordion-header-text {
      color: $elysia-Sky-color;
      text-decoration: underline;
    }

    .accordion-button.collapsed::after {
      --bs-accordion-btn-icon: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"%3E%3Cpath d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z" fill="%230A7DA4"/%3E%3C/svg%3E');

    }
  }


  .custom-accordion-header-text {
    margin-right: auto;
    font-size: 14px;
    font-weight: 400;
    color: $informa-grey-color;
  }

  .accordion-button.collapsed::after {
    margin-left: unset;
    margin-right: 12px;
    --bs-accordion-btn-icon-width: 12px;
    --bs-accordion-btn-icon: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"%3E%3Cpath d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z" fill="%23DADADA"/%3E%3C/svg%3E');
    transform: rotate(270deg);
  }

  .accordion-button:not(.collapsed):hover::after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"%3E%3Cpath d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z" fill="%230A7DA4"/%3E%3C/svg%3E');
  }

  .accordion-button:not(.collapsed)::after {
    margin-left: unset;
    margin-right: 12px;
    margin-top: 6px;
    --bs-accordion-btn-icon-width: 12px;
    transform: rotate(0deg);
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"%3E%3Cpath d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z" fill="%23DADADA"/%3E%3C/svg%3E');
  }

  .accordion-button.collapsed:hover::after {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none"%3E%3Cpath d="M10.59 0L6 4.32659L1.41 0L0 1.33198L6 7L12 1.33198L10.59 0Z" fill="%230A7DA4"/%3E%3C/svg%3E');

  }

  .custom-accordion-body {
    --bs-accordion-body-padding-y: 2px;
    --bs-accordion-body-padding-x: 0px;
    width: 260px;
  }

  .history-badge {
    display: inline-block;
    right: 0px;
    background-color: #F8F5FF;
    font-size: 12px;
    padding: 4px 8px;
    animation: fadeIn 1s linear;
    border-radius: 5px;
    color: #525A5C;

    span {
      margin-left: 5px;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0
    }

    100% {
      opacity: 1;
    }
  }

  .history-badge.none {
    display: none
  }

  .chat-history-display {
    background-color: $base-grey-color;
    border: 1px solid $base-grey-color;
    align-items: center;
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .history-title-button-text {
      color: $primary-black-color;
      background-color: $base-grey-color;
      font-size: 14px;
      border: none;
      width: 85%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus,
      &:focus-visible {
        --bs-btn-focus-box-shadow: 0px 0px 2px 0px #0A7DA4;
        border-radius: 0px;
      }
    }

    .input-for-rename {
      font-size: 16px;

      &:focus,
      &:focus-visible {
        width: 261px;
        height: 46px;
        background: $base-grey-color;
        border: 1px solid $elysia-Sky-color;
        outline: none;
        box-shadow: none;
      }
    }

    .more-options {
      width: 10%;
      position: initial;
      display: none !important;

      .dropdown-toggle::after {
        display: none !important;
      }

      .dropdown-item-anchor-button {
        --bs-dropdown-item-padding-x: 8px;
        --bs-dropdown-item-padding-y: 8px;
      }

      .dropdown-item-anchor-button:hover {
        border: 1px solid $light-grey-color;
        border-width: 1px 0px;
      }

      .dropdown-context-menu {
        width: 204px;
        --bs-dropdown-padding-y: 12px;
        right: -100px !important;

        @media (max-width: 767px) {
          right: -65px !important
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;
        font-size: 14px;
      }
    }

    &:hover {
      border: 1px solid #DADADA;
      color: #000000;
      border-radius: 5px;
      background-color: $base-grey-color;
      position: initial;

      .more-options {
        display: unset !important;
      }
    }
  }

  .active-state {
    border: 1px solid #DADADA;
    color: #000000;
    border-radius: 5px;
    background-color: $base-grey-color;
    position: inherit;

    .more-options {
      display: unset !important;
    }
  }
}

.touch-device {
  .custom-accordion {
    .chat-history-display {
      .more-options {
        display: block !important;
      }
    }
  }
}

.tooltip-sidebar {
  position: fixed !important;
  .tooltip-inner {
    background-color: $elysia-Sky-color;
    color: $primary-white-color;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 12px;
  }

  .tooltip-arrow {
    display: none;
  }
}

.back-to-chat {
  background-color: $base-grey-color;
  border: 1px solid $light-grey-color;
  border-radius: 3px;
  --bs-btn-padding-x: 0.5rem;
  height: 36px;

  .back-to-chat-text {
    color: $primary-black-color;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    background-color: $base-grey-color;
    border: 1px solid $elysia-Sky-color;
    --bs-btn-active-border-color: #0A7DA4;
    --bs-btn-active-bg: #FCFCFC;

    .back-to-chat-text {
      color: $elysia-Sky-color;
    }
  }
}

@media only screen and (max-width: 991px) {
  .open-sidebar-mobile {
    button {
      border-radius: 3px;
      padding: 2px 0px 1px 0px !important;
      background-color: $base-grey-color;
      border: 1px solid $light-grey-color;
      margin-right: 12px !important;
      height: 32px;
      width: 32px;

      &.selected,
      &:hover,
      &:focus-visible {
        background-color: $base-grey-color;
        border: 1px solid $light-grey-color;
        --bs-btn-active-border-color: #0A7DA4;
      }
    }
  }

  .mobile-branding {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .logout-icon {

    svg {
      height: 26px;
      width: 26px;
    }
  }

  .custom-nav {
    padding: 10px 12px;
  }
}

button.create-option {
  font-size: 14px;
  color: $informa-indigo !important;
  margin-bottom: 10px;

  &:hover,
  &:focus,
  &:focus-visible {
    color: $informa-grey-color !important;
    background-color: $base-grey-color !important;
  }
}